// eslint-disable-next-line @typescript-eslint/no-unused-vars
import cx from "classnames";
import { graphql, Link } from "gatsby";
import React from "react";
import { BlogPostQuery } from "../../graphql-types";
import AvatarSm from "./AvatarSm";
import "./BlogPost.css";
import css from "./BlogPost.module.css";
import Layout from "./layouts/Layout";
import SEO from "./SEO";

type Props = {
  data: BlogPostQuery;
};

export default function BlogPost({ data }: Props) {
  const post = data.markdownRemark;
  if (!post) {
    return <Layout />;
  }
  const title = post.frontmatter?.title || "";
  const excerpt = post.excerpt || "";
  return (
    <Layout>
      <SEO title={title} description={excerpt} />
      <article className={cx("blog-post__article", css.article)}>
        <header className={css.header}>
          <AvatarSm />
          <h1 className={css.title}>{title}</h1>
          <p>
            <time className={css.date}>{post.frontmatter?.date}</time>
          </p>
        </header>
        <main
          className={css.main}
          dangerouslySetInnerHTML={{ __html: post.html || "" }}
        />
        <footer className={css.footer}>
          <div>
            <Link className={css.back} to="/blog">
              Back to Blog
            </Link>
          </div>
        </footer>
      </article>
    </Layout>
  );
}

export const query = graphql`
  query BlogPost($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        date
        title
      }
    }
  }
`;
