import { Link } from "gatsby";
import React from "react";
import matt from "../images/matt_shades.jpg";
import css from "./AvatarSm.module.css";

export default function AvatarSm(props: { className?: string }) {
  return (
    <Link className={props.className} to="/">
      <img className={css.avatar} src={matt} alt="Matt Jesuele" />
    </Link>
  );
}
